import { useState } from "react";
import styled, { css } from "styled-components";
import { FormattedMessage } from "@/i18n/i18n-client";
import { FlexCol, FlexRow, H3, Overlay } from "@/shared/globals";
import AccountButton from "../components/SideNavigation/MobileAccountButton";
import { MobileLanguageSwitcher } from "../components/SideNavigation/MobileLanguageSwitcher";
import { SocialLinks } from "@/components/UtilityComponents/SocialLinks";
import { User } from "@/assets/Icons/User";
import { Delete } from "@/assets/Icons/Delete";
import { LongTailRightArrow } from "@/assets/Icons/LongTailRightArrow";
import { MobileNavigationProps } from "../../types";
import MobileMenu from "../components/SideNavigation/MobileMenu";
import { rtl, themeColor } from "@/shared/styles-utils";
import { ZIndex } from "@/shared/globals/types";
import { useAuth } from "@/lib/Authentication/hooks/useAuth";
import { Link, usePurePathname } from "@/i18n/i18n-navigation";

const MobileNavigation = ({
  open,
  onClose,
  socialSettings,
  menus,
}: MobileNavigationProps) => {
  const { isLoggedIn } = useAuth();
  const [isMyAccount, setIsMyAccount] = useState(false);
  const pathname = usePurePathname();

  const closeAccountTab = () => setIsMyAccount(false);

  return (
    <>
      {open && (
        <Overlay
          onClick={() => {
            onClose?.();
            closeAccountTab();
          }}
        />
      )}
      <Container open={open}>
        <StyledFlexRow fullWidth justifyContent="space-between" hasShadow>
          {isMyAccount ? (
            <FlexRow fullWidth spacing="xs">
              <FlipSvg onClick={closeAccountTab}>
                <LongTailRightArrow />
              </FlipSvg>
              <H3 fontSize="18px" fontWeight={600}>
                <FormattedMessage defaultMessage="My Account" id="2bGejb" />
              </H3>
            </FlexRow>
          ) : (
            <H3 fontSize="18px" fontWeight={600}>
              <FormattedMessage defaultMessage="Menu" id="tKMlOc" />
            </H3>
          )}

          <span
            style={{ opacity: 0.4 }}
            onClick={() => {
              onClose?.();
              closeAccountTab();
            }}
          >
            <Delete />
          </span>
        </StyledFlexRow>

        <ScrollableSection fullWidth spacing="m">
          {isMyAccount ? (
            <AccountButton closeAccountTab={closeAccountTab} />
          ) : (
            <>
              {isLoggedIn ? (
                <StyledFlexRow
                  fullWidth
                  spacing="s"
                  onClick={() => setIsMyAccount(true)}
                >
                  <User />
                  <H3 fontWeight={600}>
                    <FormattedMessage defaultMessage="My Account" id="2bGejb" />
                  </H3>
                </StyledFlexRow>
              ) : pathname === "/login" ||
                pathname === "/registration" ? null : (
                <StyledFlexRow fullWidth>
                  <Link href="/login">
                    <H3 fontWeight={600}>
                      <FormattedMessage defaultMessage="Login" id="AyGauy" />
                    </H3>
                  </Link>
                </StyledFlexRow>
              )}

              <MobileMenu items={menus} />

              {!!socialSettings?.length && (
                <SocialLinksWrapper
                  fullWidth
                  alignItems="flex-start"
                  spacing="s"
                >
                  <H3 fontWeight={600}>
                    <FormattedMessage
                      defaultMessage="Follow us on"
                      id="gF/sCa"
                    />
                  </H3>

                  <SocialLinks socialSettings={socialSettings} />
                </SocialLinksWrapper>
              )}
            </>
          )}
        </ScrollableSection>

        <MobileLanguageSwitcher />
      </Container>
    </>
  );
};

export default MobileNavigation;

// Styles

const Container = styled(FlexCol)<{ open: boolean }>`
  background-color: ${({ theme }) => theme.bg.reverse};
  position: fixed;
  top: 0;
  ${rtl("right: 0;", "left: 0;")}
  height: 100%;
  width: 80vw;
  max-width: 340px;
  box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  opacity: ${({ open }) => (open ? "1" : "0")};
  transform: ${({ open }) =>
    open ? "translateX(0)" : rtl("translateX(120%)", "translateX(-120%)")};
  z-index: ${ZIndex["OVERLAY"]};
`;

const ScrollableSection = styled(FlexCol)`
  overflow-y: auto;
  height: 100%;
  padding-block-start: 16px;
`;

const StyledFlexRow = styled(FlexRow)<{ hasShadow?: boolean }>`
  padding: 16px;
  background-color: ${themeColor("white")};
  ${({ hasShadow }) =>
    hasShadow &&
    css`
      box-shadow: 0px 4px 4px 0px rgba(226, 226, 226, 0.5);
      z-index: ${ZIndex["DEFAULT"]};
    `}
`;

const FlipSvg = styled.div`
  cursor: pointer;
  svg {
    ${rtl("", "transform: rotate(180deg)")}
  }
`;

const SocialLinksWrapper = styled(FlexCol)`
  padding: 16px;
  background-color: ${themeColor("white")};
`;
