import { StoreTemplate } from "@/templates/TemplateLoader";
import { ReactNode } from "react";
import SectionContent from "../elements/SectionContent";

// default
import Logo from "@/templates/default/elements/Logo";

const elements = {
  SectionContent,
  Logo,
};

export function SiteLayoutLoader(props: { children: ReactNode }) {
  StoreTemplate.load("black-and-white", "site-layout", elements);
  return props.children;
}
