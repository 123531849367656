export const Global = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_4538_16936)">
      <path
        d="M2.56365 6.23601L6.13701 8.78837C6.32295 8.92118 6.41592 8.98759 6.51322 9.00289C6.59884 9.01635 6.68652 9.00278 6.76406 8.96407C6.85219 8.92008 6.92074 8.82868 7.05784 8.64588L7.81292 7.63909C7.85169 7.5874 7.87108 7.56155 7.89386 7.53925C7.91409 7.51945 7.93629 7.50177 7.9601 7.48647C7.98693 7.46924 8.01645 7.45612 8.0755 7.42988L11.2993 5.99707C11.433 5.93767 11.4998 5.90798 11.5502 5.8616C11.5947 5.82057 11.6298 5.77033 11.653 5.71436C11.6792 5.65108 11.684 5.57812 11.6938 5.43221L11.9298 1.8915M11.2503 11.2503L13.4303 12.1846C13.6833 12.293 13.8097 12.3472 13.8767 12.4372C13.9355 12.5162 13.9644 12.6135 13.9583 12.7117C13.9514 12.8237 13.8751 12.9382 13.7224 13.1672L12.6982 14.7035C12.6259 14.812 12.5897 14.8663 12.5419 14.9056C12.4996 14.9403 12.4508 14.9664 12.3984 14.9824C12.3392 15.0003 12.274 15.0003 12.1435 15.0003H10.4808C10.308 15.0003 10.2217 15.0003 10.1481 14.9714C10.0832 14.9459 10.0258 14.9045 9.98101 14.851C9.93034 14.7904 9.90302 14.7084 9.84838 14.5445L9.25432 12.7623C9.22209 12.6656 9.20597 12.6173 9.20173 12.5681C9.19797 12.5246 9.20109 12.4807 9.21097 12.4381C9.22212 12.3901 9.24491 12.3445 9.29049 12.2533L9.74175 11.3508C9.83343 11.1674 9.87928 11.0758 9.94989 11.0188C10.0121 10.9687 10.0875 10.9375 10.1669 10.9289C10.2571 10.9193 10.3544 10.9517 10.5489 11.0165L11.2503 11.2503ZM18.3337 10.0003C18.3337 14.6027 14.6027 18.3337 10.0003 18.3337C5.39795 18.3337 1.66699 14.6027 1.66699 10.0003C1.66699 5.39795 5.39795 1.66699 10.0003 1.66699C14.6027 1.66699 18.3337 5.39795 18.3337 10.0003Z"
        stroke="#667085"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4538_16936">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
