import type { NavBarProps, TemplateElement } from "../../types";
import DesktopNavbar from "../components/NavBar/DesktopNavbar";
import MobileNavbar from "../components/NavBar/MobileNavbar";
import { StoreTemplate } from "../../TemplateLoader";
import useBreakPoints from "@/shared/utils/useBreakPoints";

const NavBar: TemplateElement<NavBarProps> = ({
  menu,
  socialMedia,
  itemsTotal,
  itemsCount,
  toggleSideCart,
}) => {
  const Template = StoreTemplate.get();
  const { isTablet, isLoading } = useBreakPoints();

  return (
    <>
      <Template.SideCart />
      <Template.CartPopup />
      {(isTablet || isLoading) && (
        <MobileNavbar menu={menu} socialMedia={socialMedia} />
      )}

      {(!isTablet || isLoading) && (
        <DesktopNavbar
          menu={menu}
          itemsTotal={itemsTotal}
          itemsCount={itemsCount}
          toggleSideCart={toggleSideCart}
        />
      )}
    </>
  );
};

export default NavBar;

/* at first load, we may need to render both components and let the css decide to hide which
 but after that, we need to remove the not-rendered component from the dom
 actually this is not the best way, we may need to find a better way
 but i have come up with this method for these reasons
 - we can read the headers() and detect the device from it, but it would make the page to be dynamic rendered
 - we can use parallel routes from NextJS, but conflict with out template
 - we can use BrowserOnly component, but it would affect the LCP core vital
 - we can render the one of them as an initial render, but it would affect the layout shift from core vital   
*/
