import * as React from "react";
import styled from "styled-components";
import { MenuArrow } from "@/assets/Icons/MenuArrow";
import { SocialLinks } from "@/components/UtilityComponents/SocialLinks";
import { useStore } from "@/lib/storeData/StoreContext";
import { NavigationLink } from "../components/NavBar/NavigationLink/NavigationLink";
import { rtl, themeColor } from "@/shared/styles-utils";
import type {
  MenuItemProps,
  MenuProps,
  MenuWithChildrenType,
} from "../components/NavBar/menu/types";
import HTMLRender from "@/shared/globals/UiElements/HTMLRender";
import MotionElementWrapper from "@/shared/globals/MotionElementWrapper";
import { isCurrentRoute } from "@/shared/utils/isCurrentRoute";
import tailwindConfig from "@/../tailwind.config";
import { useParams, usePurePathname } from "@/i18n/i18n-navigation";

function checkIsActive(activePath: string[], pageId: string): boolean {
  return activePath.indexOf(pageId) !== -1;
}

const DesktopMenu = ({ items }: { items: MenuWithChildrenType }) => {
  const [activePath, setActivePath] = React.useState<string[]>([]);

  const handleOpenSubMenu = React.useCallback<MenuProps["onOpenSubMenu"]>(
    (itemId, level) => {
      const newPath = [...activePath];
      newPath[level - 1] = itemId!;
      setActivePath(newPath);
    },
    [activePath]
  );

  const { socialMedia: socialSettings } = useStore();

  const handleCloseSubMenu = React.useCallback<(level: number) => void>(
    (level) => {
      setActivePath(activePath.splice(0, level - 1));
    },
    [activePath]
  );

  return (
    <StyledDesktopNav>
      <Menu
        parentId={null}
        activePath={activePath}
        onCloseSubMenu={handleCloseSubMenu}
        onOpenSubMenu={handleOpenSubMenu}
        menu={items}
        level={1}
      />
      <IconsWrapper>
        <SocialLinks socialSettings={socialSettings} isCircle={false} />
      </IconsWrapper>
    </StyledDesktopNav>
  );
};

const Menu = ({
  activePath,
  parentId,
  onOpenSubMenu,
  onCloseSubMenu = null,
  menu,
  level,
}: MenuProps) => {
  const handleMouseLeave = React.useCallback(() => {
    if (onCloseSubMenu) {
      onCloseSubMenu(level);
    }
  }, [level, onCloseSubMenu]);

  const StyledMenu =
    level === 1
      ? StyledTopMenu
      : level === 2
        ? StyledDropMenu
        : StyledDropSubMenu;

  const isOpen = checkIsActive(activePath, parentId!);

  if (!menu || (level > 1 && !isOpen)) return null;

  return (
    <MotionElementWrapper
      initial={level === 2 ? { top: "%50", opacity: 0 } : { opacity: 0 }}
      animate={level === 2 ? { top: "100%", opacity: 1 } : { opacity: 1 }}
      className={`${level === 2 ? "absolute z-TOOLTIP" : ""}`}
      key={`${isOpen}`}
    >
      <StyledMenu
        key={parentId! + level}
        onMouseLeave={handleMouseLeave}
        isOpen={isOpen}
        level={level}
      >
        <StyledInnerMenu data-test="main-menu-list" level={level}>
          {menu.map((item) => {
            return (
              <MenuItem
                activePath={activePath}
                onOpenSubMenu={onOpenSubMenu}
                isActive={checkIsActive(activePath, item.id)}
                key={item.id}
                level={level}
                item={item}
              />
            );
          })}
        </StyledInnerMenu>
      </StyledMenu>
    </MotionElementWrapper>
  );
};

const MenuItem = ({
  item,
  isActive,
  level,
  activePath,
  onOpenSubMenu,
}: MenuItemProps) => {
  const pathname = usePurePathname();
  const params = useParams();
  const isCurrent = isCurrentRoute(pathname, params, item);

  const handleMouseEnter = React.useCallback(() => {
    onOpenSubMenu(item.id, level);
  }, [item.id, level, onOpenSubMenu]);

  if (!item.children) return null;
  return (
    <StyledMenuItem
      isActive={isActive}
      onMouseEnter={handleMouseEnter}
      isCurrent={isCurrent && !item.parentId}
      level={level}
    >
      <StyledMenuItemContent data-test="main-menu-item" isActive={isActive}>
        <NavigationLink {...item.link} type={item?.link?.type!}>
          <HTMLRender html={item?.icon} />
          {item.title}
        </NavigationLink>
      </StyledMenuItemContent>
      {item.children.length === 0 ? null : (
        <>
          <IconWrapper data-test="main-menu-item-arrow" isOpen={isActive}>
            <MenuArrow />
          </IconWrapper>
          <Menu
            parentId={item.id}
            activePath={activePath}
            menu={item.children}
            level={level + 1}
            onCloseSubMenu={null}
            onOpenSubMenu={onOpenSubMenu}
          />
        </>
      )}
    </StyledMenuItem>
  );
};

export default DesktopMenu;

/**
 *
 *
 * Styles
 *
 *
 */

const StyledInnerMenu = styled.div<{
  level: number;
}>`
  border-radius: ${({ level }) => level > 1 && "0px 0px 16px 16px"};
`;
const StyledDesktopNav = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 10px 0;
`;

const IconsWrapper = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }
`;

const StyledMenuItemContent = styled.div<{ isActive: boolean }>`
  flex: 1 1 auto;
  display: flex;
  & > a,
  & > a:active,
  & > a:hover,
  & > span {
    text-decoration: none;
    color: #000;
    padding: 10px 8px;
    width: 100%;
  }
`;

const StyledMenuItem = styled.div<{
  isActive: boolean;
  isCurrent?: boolean;
  level: number;
}>`
  padding: 8px;
  display: flex;
  color: ${({ theme }) => theme.text.default};
  position: relative;
  background-color: ${({ isActive }) =>
    isActive ? tailwindConfig.theme.extend.colors.gray[100] : "transparent"};
  font-size: ${({ level }) => (level > 1 ? "14px" : "16px")};
  align-items: center;
  cursor: pointer;
  border-bottom: 2px solid;
  border-color: ${({ isCurrent }) =>
    isCurrent ? themeColor("primary") : "transparent"};
  ${rtl("margin-left", "margin-right")}: 4px;
  margin: 0;
  font-weight: ${({ level }) => (level > 1 ? "400" : "600")};
  border-radius: ${({ level }) => level > 1 && "12px"};
`;

const IconWrapper = styled.span<{ isOpen: boolean }>`
  color: ${({ isOpen }) => (isOpen ? "#252A31" : themeColor("primary"))};
`;

const StyledTopMenu = styled.div<{ isOpen: boolean; level: number }>`
  display: flex;
  justify-content: center;
  & > ${StyledInnerMenu} {
    display: flex;
    margin: 0;
    padding: 0;
    flex-direction: row;
    justify-content: center;
  }
`;

const StyledDropMenu = styled.div<{ isOpen: boolean; level: number }>`
  min-width: 260px;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  position: absolute;
  flex-direction: column;
  top: calc(100% + 2px);
  left: ${rtl("auto", "-8px")};
  right: ${rtl("-8px", "auto")};
  margin: 0;
  list-style: none;
  flex-direction: column;
  border-radius: ${({ level }) => level === 2 && "0px 0px 16px 16px"};
  box-shadow: ${({ level }) =>
    level === 2 && tailwindConfig.theme.extend.boxShadow.md};
  padding: 8px;
  background-color: #fff;
  & ${IconWrapper} {
    transform: rotate(${rtl("90deg", "-90deg")});
  }
  & > ${StyledInnerMenu} {
    list-style: none;
    flex-direction: column;
    background: #fff;
  }
`;

const StyledDropSubMenu = styled.div<{ isOpen: boolean; level?: number }>`
  min-width: 210px;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  padding: 4px 0;
  background: #fff;
  position: absolute;
  flex-direction: column;
  left: ${rtl("auto", "calc(100% + 8px)")};
  right: ${rtl("calc(100% + 8px)", "auto")};
  top: -8px;
  margin: 0;
  list-style: none;
  box-shadow: ${tailwindConfig.theme.extend.boxShadow.sm};
  border-radius: ${rtl("16px 4px 4px 16px", "4px 16px 16px 4px")};
  padding: 8px;
`;
