import { FC } from "react";
import { FormattedMessage } from "@/i18n/i18n-client";
import { EmptyState } from "@/components/UtilityComponents/EmptyState";
import { Overlay } from "@/shared/globals";
import { Delete } from "@/assets/Icons/Delete";
import { EmptyCartIcon } from "@/assets/Icons/EmptyCartIcon";
import { OutlinedShoppingBasket } from "@/assets/Icons/OutlinedShoppingBasket";
import Flex from "@/shared/globals/UiElements/Flex";
import { useEscapeAndStopScrollingEffect } from "@/hooks/useEscapeAndStopScrollingEffect";
import SideCartSingleItem from "../components/SideCart/SideCartSingleItem";
import { SideCartProps } from "../../types";
import { ButtonHolder } from "@/components/Hero";
import { useCartData } from "@/lib/cartData/useCartData";
import Button from "../components/Button/Button";
import { useTheme } from "styled-components";
import { useStore } from "@/lib/storeData/StoreContext";
import { isCartDirty } from "@/shared/utils/error-utils";
import { Link } from "@/i18n/i18n-navigation";

const SideCart: FC<SideCartProps> = () => {
  const {
    cart: { isLoading, items, cartErrors, isSideCartOpen, id },
    setSideCartState,
  } = useCartData();

  const canNotProceedToCheckout = isCartDirty(cartErrors);

  const { isRTL } = useTheme();
  const { appearance } = useStore();

  useEscapeAndStopScrollingEffect({
    isOpen: !!isSideCartOpen,
    onClose: () => setSideCartState(false),
  });
  return (
    <>
      {isSideCartOpen && (
        <Overlay
          className="!bg-gray-900"
          onClick={() => setSideCartState(false)}
        />
      )}

      <Flex
        column
        width="300px"
        spacing="none"
        className={`fixed !h-screen z-OVERLAY top-0 bg-white shadow-sm
            transition-all ease-in-out duration-300
            ${isRTL ? "left-0" : "right-0 "}
            ${
              isSideCartOpen
                ? " opacity-100 translate-x-0"
                : `opacity-0 ${
                    isRTL ? "-translate-x-[350px]" : "translate-x-[350px]"
                  }`
            }`}
      >
        {isSideCartOpen && (
          <>
            <Flex
              fullWidth
              className="border-b border-gray-200 px-lg py-md "
              justifyContent="space-between"
              alignItems="center"
            >
              <Flex spacing="s" alignItems="center">
                <OutlinedShoppingBasket />
                <p className="text-gray-800 font-semibold m-0">
                  <FormattedMessage
                    defaultMessage="Shopping Cart"
                    id="KFD6en"
                  />
                </p>
              </Flex>
              <Flex
                data-test="button-close-cart"
                onClick={() => setSideCartState(false)}
                style={{
                  color: appearance?.colors?.primary!,
                }}
              >
                <Delete small />
              </Flex>
            </Flex>
            {!items || items?.length === 0 ? (
              <EmptyState
                title={
                  <div className="!text-base">
                    <FormattedMessage
                      defaultMessage="Your cart is empty!"
                      id="H4csrx"
                    />
                  </div>
                }
                description={
                  <div className="!text-center !w-full">
                    <FormattedMessage
                      defaultMessage="Looks like you haven’t added anything to your cart yet"
                      id="jd4m/q"
                    />
                  </div>
                }
                image={<EmptyCartIcon />}
                button={
                  <Link href="/shop" fullWidth>
                    <ButtonHolder
                      onClick={() => setSideCartState(false)}
                      className="!mr-0 "
                      fullWidth
                    >
                      <FormattedMessage
                        defaultMessage="Start Shopping"
                        id="BRgXJI"
                      />
                    </ButtonHolder>
                  </Link>
                }
                padding="20px"
                maxWidth="75%"
              />
            ) : (
              <Flex
                fullWidth
                fullHeight
                column
                spacing="none"
                data-test="cart-items-list"
                className="overflow-y-auto"
              >
                {(items || [])?.map((item) => (
                  <SideCartSingleItem
                    key={item?.id}
                    item={item}
                    errors={cartErrors}
                  />
                ))}
              </Flex>
            )}
            {items?.length > 0 && (
              <Flex column className="p-lg border-t border-gray-200">
                <Button
                  data-test="button-checkout"
                  fullWidth
                  onClick={() => setSideCartState(false)}
                  to={`/checkout/${id}`}
                  isDisabled={isLoading || canNotProceedToCheckout}
                >
                  <FormattedMessage defaultMessage="Checkout" id="BJ2TKX" />
                </Button>

                <Button
                  data-test="button-view-cart"
                  fullWidth
                  onClick={() => setSideCartState(false)}
                  to="/cart"
                  type="secondary"
                >
                  <FormattedMessage defaultMessage="View Cart" id="5BTYa0" />
                </Button>
              </Flex>
            )}
          </>
        )}
      </Flex>
    </>
  );
};

export default SideCart;
