import type { NavBarProps, TemplateElement } from "../../types";
import DesktopNavbar from "../components/NavBar/DesktopNavbar";
import MobileNavbar from "../components/NavBar/MobileNavbar";
import useBreakPoints from "@/shared/utils/useBreakPoints";
import SideCart from "./SideCart";
import CartPopup from "@/templates/default/elements/CartPopup";

const NavBar: TemplateElement<NavBarProps> = ({
  menu,
  socialMedia,
  itemsTotal,
  itemsCount,
  toggleSideCart,
}) => {
  const { isTablet, isLoading } = useBreakPoints();

  return (
    <>
      <SideCart />
      <CartPopup />
      {(isTablet || isLoading) && (
        <MobileNavbar menu={menu} socialMedia={socialMedia} />
      )}

      {(!isTablet || isLoading) && (
        <DesktopNavbar
          menu={menu}
          itemsTotal={itemsTotal}
          itemsCount={itemsCount}
          toggleSideCart={toggleSideCart}
        />
      )}
    </>
  );
};

export default NavBar;
