import { FC, useRef } from "react";
import styled from "styled-components";
import { ShoppingBagDefault } from "@/assets/Icons/ShoppingBagDefault";
import { LanguageSwitcher } from "./LanguageSwitcher/LanguageSwitcher";
import { Price } from "@/components/Price";
import Flex from "@/shared/globals/UiElements/Flex";
import SearchBar from "./SearchBar/SearchBar";
import { Section } from "@/shared/layout";
import { themeColor } from "@/shared/styles-utils";
import { NavBarProps } from "../../../types";
import AccountButton from "./DesktopAccountButton";
import { useInView } from "framer-motion";
import MotionElementWrapper from "@/shared/globals/MotionElementWrapper";
import DesktopMenu from "../../elements/DesktopMenu";
import Logo from "../../elements/Logo";

const DesktopNavbar: FC<NavBarProps> = ({
  itemsCount,
  menu,
  itemsTotal,
  toggleSideCart,
}) => {
  const navbarRef = useRef<HTMLDivElement>(null);
  const inNavbarInView = useInView(navbarRef);

  return (
    <ShowAboveMobile ref={navbarRef}>
      <Section boxShadow background="white">
        <UpperNavbarComponent
          itemsCount={itemsCount}
          itemsTotal={itemsTotal}
          toggleSideCart={toggleSideCart}
        />
        <DesktopMenu items={menu!} />
      </Section>

      {!inNavbarInView && (
        <MotionElementWrapper
          initial={{ opacity: 0, y: "-100%" }}
          animate={{ opacity: 1, y: "0" }}
          className="fixed top-0 w-full  shadow-md"
        >
          <Section boxShadow background="white">
            <UpperNavbarComponent
              itemsCount={itemsCount}
              itemsTotal={itemsTotal}
              toggleSideCart={toggleSideCart}
            />
          </Section>
        </MotionElementWrapper>
      )}
    </ShowAboveMobile>
  );
};

export default DesktopNavbar;

/**
 *
 * Styles
 *
 */

const UpperNavbarComponent = ({ itemsCount, itemsTotal, toggleSideCart }) => (
  <Flex justifyContent="space-between" spacing="xxxl" className="py-md">
    <Flex spacing="xxl" alignItems="center" className="flex-1">
      <Logo />
      <SearchBar />
    </Flex>

    <Flex spacing="xxl" alignItems="center">
      <AccountButton />
      <CartButton data-test="button-side-cart" onClick={toggleSideCart}>
        <Price money={itemsTotal} className="font-semibold !text-[14px]" />
        <span className="text-xs">
          <ShoppingBagCount>{itemsCount}</ShoppingBagCount>
          <ShoppingBagDefault />
        </span>
      </CartButton>
      <LanguageSwitcher />
    </Flex>
  </Flex>
);

const ShowAboveMobile = styled.div`
  display: none;
  @media (min-width: 769px) {
    display: block;
  }
`;

const CartButton = styled.button`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  background: #f2f4f7;
  padding: 10px;
  border-radius: 2rem;
  border: none;
  margin: 0;
  line-height: 0;
  cursor: pointer;
  svg {
    margin-inline-end: 10px;
  }
  position: relative;
`;

const ShoppingBagCount = styled.span`
  background: ${themeColor("primary")};
  color: ${themeColor("white")};
  font-weight: 400;
  width: 16px;
  height: 16px;
  padding: 4px;
  font-size: 12px;
  border-radius: 9999px;
  min-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  top: 0px;
  transform: translateY(-50%);
`;
