import { ReactElement, useState } from "react";
import type { MenuWithChildrenType } from "./menu/types";
import { SocialLinkType } from "@/generated/graphql";
import { BurgerIcon } from "@/assets/Icons/BurgerIcon";
import useRouteChange from "@/hooks/useRouteChange";
import { useEscapeAndStopScrollingEffect } from "@/hooks/useEscapeAndStopScrollingEffect";
import { StoreTemplate } from "../../../TemplateLoader";

const BurgerMenu = ({
  socialSettings,
  menus,
  icon = <BurgerIcon />,
}: {
  socialSettings: (SocialLinkType | null)[];
  menus: MenuWithChildrenType;
  icon?: ReactElement;
}) => {
  const Template = StoreTemplate.get();
  const [isOpen, setIsOpen] = useState(false);
  useRouteChange(() => setIsOpen(false));
  useEscapeAndStopScrollingEffect({ isOpen, setIsOpen });
  return (
    <>
      <span onClick={() => setIsOpen(true)}>{icon}</span>
      <Template.MobileNavigation
        menus={menus}
        socialSettings={socialSettings}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};

export default BurgerMenu;
