import styled from "styled-components";
import { FlexCol, FlexRow, H3, Span } from "../../../../shared/globals";
import { PrimaryButton } from "../../../../components/Button";
import { rtl, themeColor } from "../../../../shared/styles-utils";
import { ZIndex } from "../../../../shared/globals/types";

export const Container = styled(FlexCol)<{ isOpen: boolean }>`
  position: fixed;
  ${rtl("left", "right")}: 0;
  top: 0;
  height: 100%;
  width: 300px;
  background-color: ${themeColor("white")};
  box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  ${rtl("padding-left", "padding-right")}: 10px;
  z-index: ${ZIndex["OVERLAY"]};

  transition: all 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  transform: ${({ isOpen }) =>
    isOpen ? "translateX(0)" : rtl("translateX(-120%)", "translateX(120%)")};
`;

export const FullWidth = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
`;

export const CartHeader = styled(FullWidth)`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
`;

export const ProductTitle = styled(H3)`
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 4px;
`;

export const SelectedOptions = styled(Span)`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme: { text } }) => text.inkLight};
  text-transform: capitalize;
  padding-bottom: 4px;
`;

export const ProductPrice = styled.div`
  padding-bottom: 6px;

  span {
    font-size: 12px;
    font-weight: bold;
    padding-bottom: 6px;
  }
`;

export const StyledH3 = styled(H3)`
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 4px;
`;

export const StyledH3WithMargin = styled(StyledH3)`
  ${rtl("margin-right", "margin-left")}: 10px;
`;

export const ItemsContainer = styled(FlexCol)`
  width: 100%;
  flex: 1 1 auto;
  overflow-y: auto;
  ${rtl("padding-left", "padding-right")}: 10px;
`;

export const ItemHolder = styled(FlexCol)`
  width: 100%;
  margin-bottom: 20px;
  word-break: break-word;
  &:not(:last-child) {
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
  }
`;

export const ItemInfo = styled(FlexCol)`
  margin-inline-end: 10px;
`;

export const TransparentButton = styled.button<{ disabled?: boolean }>`
  color: #989898;
  border: none;
  padding: 0;
  background-color: inherit;
  outline: none;
  display: flex;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

export const DummyQuantityInput = styled.div`
  position: relative;
  height: 30px;
  margin-top: 5px;
`;

export const CheckoutSection = styled(FlexCol)`
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid #eee;
  bottom: 0;
  padding-top: 20px;
`;

export const StyledSubTotal = styled(StyledH3)`
  color: ${themeColor("primary")};
`;

export const CheckoutButton = styled(PrimaryButton)`
  & svg {
    transform: scaleX(${rtl("-1", "1")});
  }
`;

export const MarginBottom = styled.div`
  width: inherit;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const MobileClose = styled(TransparentButton)`
  color: ${({ theme }) => theme.bg.wash};
  border-radius: 35px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  padding: 10px;
  position: absolute;
  top: 45px;
  ${rtl("right", "left")}: -20px;
  transform: scaleX(${rtl("1", "-1")});

  @media (min-width: 768px) {
    display: none;
  }
`;

export const CloseButton = styled(TransparentButton)`
  color: ${themeColor("primary")};
  ${rtl("padding-left", "padding-right")}: 10px;
  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
`;
