import { StoreTemplate } from "@/templates/TemplateLoader";
import { ReactNode } from "react";
import Logo from "@/templates/farah-omar/elements/Logo";

// default
import FixedNav from "@/templates/default/elements/FixedNav";
import DesktopMenu from "@/templates/default/elements/DesktopMenu";
import MobileNavigation from "@/templates/default/elements/MobileNavigation";
import SideCart from "@/templates/default/elements/SideCart";
import CartPopup from "@/templates/default/elements/CartPopup";
import SectionContent from "@/templates/default/elements/SectionContent";

const elements = {
  SideCart,
  CartPopup,
  FixedNav,
  DesktopMenu,
  MobileNavigation,
  Logo,
  SectionContent,
};

export function SiteLayoutLoader(props: { children: ReactNode }) {
  StoreTemplate.load("farah-omar", "site-layout", elements);
  return props.children;
}
