import { StoreTemplate } from "@/templates/TemplateLoader";
import { ReactNode } from "react";

// default
import SectionContent from "@/templates/default/elements/SectionContent";
import Logo from "@/templates/default/elements/Logo";

const elements = {
  SectionContent,
  Logo,
};

export function SiteLayoutLoader(props: { children: ReactNode }) {
  StoreTemplate.load("arab-hardware", "site-layout", elements);
  return props.children;
}
