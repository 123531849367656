import React from "react";
import { QuantityInput } from "../QuantityInput";
import { useCartData } from "../../../../lib/cartData/useCartData";

interface SideCartItemQtyProps {
  isDisabled?: boolean;
  initialQuantity: number;
  maxQuantityPerCart?: number | null;
  handleQuantityChange: (value: number) => void;
}

const SideCartItemQty: React.FC<SideCartItemQtyProps> = ({
  isDisabled = false,
  initialQuantity,
  maxQuantityPerCart,
  handleQuantityChange,
}) => {
  const {
    cart: { isLoading },
  } = useCartData();

  return (
    <QuantityInput
      value={initialQuantity}
      max={maxQuantityPerCart ?? Number.POSITIVE_INFINITY}
      onChange={(value) => {
        handleQuantityChange(value);
      }}
      disabled={isDisabled || isLoading}
    />
  );
};

export default SideCartItemQty;
