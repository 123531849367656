import { useStore } from "@/lib/storeData/StoreContext";
import useBreakPoints from "./useBreakPoints";
import { useEffect } from "react";
import { Breakpoints } from "../globals/types";

const excludedStoreIDs = [
  "Store_clwbygobi07ui01ixb7hg1hy9", // Jam jam store
  "Store_cloebqr7v096101jo1wjb04h9", // inpu
  "Store_clun3oqwj06jp01m42tmfc9du", // beauty-shades.com
  "Store_clrifiuqu0bfn01iufj211e48", // Mauritanian Store
  "Store_clwyyt6mp0ifl01ip19s08sqi", // lumir fashion
  "Store_clk6ln7ft0io501f7ga3xd32m", // Diar Mariam
  "Store_clxyhpy2301e401hf8p0h3ck4", // Toofe store
  "Store_cloqqdg0l00na01jkh0a216g2", // MOVE STORE
  "Store_clwhnzuzr0asd01ipc7u55aa0", // Star mix
  "Store_cljsnygr909pg01g445gjhw52", // ALL ZONE
  "Store_clx680pmp01m801i07qsc2gdj", // Clichei
  "Store_clxvpgabg001401hf1gp66uwl", // solovizion.com
  "Store_clya7eefi01qz01d88pa1ds4y", // orthocareoman
  "Store_clufwi8ah03lu01ji5phb3za5", // mawsoataldecor.com
  "Store_clwm21prh0cwu01ip3v6i5i9l", // elbytrawy.com
  "Store_clya7eefi01qz01d88pa1ds4y",
  "Store_clwm21prh0cwu01ip3v6i5i9l",
  "Store_clufwi8ah03lu01ji5phb3za5",
];

export function useSiteLayout() {
  const { storeId } = useStore();
  const { isSmallScreen } = useBreakPoints();

  const excludedFooter = excludedStoreIDs.includes(storeId) && isSmallScreen;

  useEffect(() => {
    if (window.innerWidth > Breakpoints.TABLET) return;
    let prevScrollpos = window.scrollY;
    const navbar = document.getElementById("navbar") as HTMLElement;
    window.onscroll = function () {
      const currentScrollPos = window.scrollY;
      if (prevScrollpos > currentScrollPos) {
        navbar.style.top = "0";
      } else {
        navbar.style.top = -navbar.offsetHeight.toString() + "px";
      }
      prevScrollpos = currentScrollPos;
    };
  }, []);

  return { excludedFooter };
}
