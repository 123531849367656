"use client";

import DefaultSiteLayout from "@/templates/default/pages/SiteLayout";
import { SiteLayoutProps } from "@/templates/types";
import { SiteLayoutLoader } from "../page-template-loaders/site-layout-loader";

const SiteLayout: React.FC<SiteLayoutProps> = (props) => {
  return (
    <SiteLayoutLoader>
      <DefaultSiteLayout {...props} />
    </SiteLayoutLoader>
  );
};

export default SiteLayout;
