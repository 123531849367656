import React from "react";
import styled from "styled-components";
import { useAuth } from "@/lib/Authentication/hooks/useAuth";
import {
  filterMePageItems,
  mePageItemsEnum,
} from "../../elements/CustomerProfile/consts";
import { useStore } from "@/lib/storeData/StoreContext";
import { themeColor } from "@/shared/styles-utils";
import Flex from "@/shared/globals/UiElements/Flex";
import { FlexRow, H3, P } from "@/shared/globals";
import { LogoutSimpleIcon } from "@/assets/Icons/LogoutSimpleIcon";
import { LongTailRightArrow } from "@/assets/Icons/LongTailRightArrow";
import { Link } from "@/i18n/i18n-navigation";

const AccountButton = ({
  closeAccountTab,
}: {
  closeAccountTab: () => void;
}) => {
  const { user, isLoggedIn, logout } = useAuth();
  const { areReviewsActivated } = useStore();

  return (
    <>
      {isLoggedIn && (
        <Container>
          <BasicInfo column verticalSpacing="m">
            <H3 fontSize="18px" fontWeight={600}>
              {user?.name}
            </H3>
            <P style={{ opacity: 0.5 }}>{user?.email}</P>
          </BasicInfo>
          {filterMePageItems(areReviewsActivated).map((item) =>
            item.id !== mePageItemsEnum.LogOut ? (
              <Link key={item.id} href={`/me/${item.slug}/`}>
                <StyledButton
                  justifyContent="space-between"
                  onClick={closeAccountTab}
                >
                  <H3>{item.title}</H3>
                  <LongTailRightArrow />
                </StyledButton>
              </Link>
            ) : (
              <LogoutButton
                key={item.id}
                spacing="s"
                onClick={() => {
                  logout();
                  closeAccountTab();
                }}
              >
                <LogoutSimpleIcon />
                <H3 fontSize="14px" fontWeight={600}>
                  {item.title}
                </H3>
              </LogoutButton>
            )
          )}
        </Container>
      )}
    </>
  );
};

export default AccountButton;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const BasicInfo = styled(Flex)`
  padding: 0 16px 16px;
`;

const StyledButton = styled(FlexRow)`
  border-bottom: 2px solid ${({ theme }) => theme.bg.reverse};
  background-color: ${themeColor("white")};
  padding: 16px;
  svg {
    transform: ${({ theme: { isRTL } }) => isRTL && "rotate(180deg)"};
  }
`;

const LogoutButton = styled(FlexRow)`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 16px;
  background-color: ${({ theme }) => theme.danger.default}1a;
  > h3 {
    color: ${({ theme }) => theme.danger.default};
  }
`;
