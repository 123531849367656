import * as React from "react";
import { FormattedMessage, useLocale } from "@/i18n/i18n-client";
import { DefaultTextPrice, DiscountPrice, Price } from "@/components/Price";
import { FlexRow, P, Spinner } from "@/shared/globals";
import SideCartItemQty from "./SideCartItemQty";
import { useCartData } from "@/lib/cartData/useCartData";
import ItemImg from "@/shared/globals/UiElements/ItemImg";
import Flex from "@/shared/globals/UiElements/Flex";
import {
  CartCustomItem,
  CartError,
  CartItem,
  CartSimpleItem,
  ProductType,
  CheckoutServiceErrorCodes as ErrorCodes,
  CartFragment,
} from "@/generated/graphql";
import { CouponIcon } from "@/assets/Icons/CouponIcon";
import { TwoDashTrashIcon } from "@/assets/Icons/TwoDashTrashIcon";
import { CustomButton } from "../Button/CustomButton";
import { theme } from "@/../tailwind.config";
import { Badge } from "@/shared/globals/UiElements/Badge";
import { getTranslatedDiscountTitle } from "@/shared/utils/getTranslatedDiscountTitle";
import { useStore } from "@/lib/storeData/StoreContext";
import {
  FormattedError,
  generateItemRelatedErrors,
  isProductNotAvailableError,
} from "@/shared/utils/error-utils";
import Alert from "@/shared/globals/UiElements/Alert";
import Link from "next/link";
import { useRouter } from "@/i18n/i18n-navigation";
// import { Link } from "@/i18n/i18n-navigation";

interface SideCartSingleItemProps {
  item: CartFragment["items"][0];
  errors?: CartError[] | null;
}

interface SideCartItemProps {
  item: CartFragment["items"][0];
  itemErrors?: FormattedError[] | null;
  isItemNotAvailable: boolean;
}

const SideCartSingleItem: React.FC<SideCartSingleItemProps> = ({
  item,
  errors,
}) => {
  const isSimpleItem = item?.product?.type === ProductType.Simple;

  const { currency: currencyCode } = useStore();

  const itemRelatedErrors = generateItemRelatedErrors(
    item as CartItem,
    currencyCode,
    errors
  );

  const isProductNotAvailableErrorFound =
    isProductNotAvailableError(itemRelatedErrors);

  if (isSimpleItem)
    return (
      <SideCartSimpleItem
        item={item}
        itemErrors={itemRelatedErrors}
        isItemNotAvailable={isProductNotAvailableErrorFound}
      />
    );
  else
    return (
      <SideCartCustomItem
        item={item}
        itemErrors={itemRelatedErrors}
        isItemNotAvailable={isProductNotAvailableErrorFound}
      />
    );
};

const SideCartSimpleItem = ({
  item,
  itemErrors,
  isItemNotAvailable,
}: SideCartItemProps) => {
  const { code: activeLocale } = useLocale();
  const typedSimpleItem = item as CartSimpleItem;

  const {
    cart: { isLoading },
    updateSimpleItem: { updateSimpleItem },
    removeSimpleItem: { removeSimpleItem },
  } = useCartData();

  const isAutomaticDiscountApplied = !!typedSimpleItem?.discounts?.length;

  const handleRemoveCartItem = async () => {
    await removeSimpleItem(
      typedSimpleItem?.id || "",
      typedSimpleItem?.variant?.id || ""
    );
  };

  const handleQuantityChange = async (value: number) => {
    await updateSimpleItem({
      item: {
        itemId: typedSimpleItem?.id || "",
        quantity: value,
        variantId: typedSimpleItem?.variant?.id || "",
      },
    });
  };

  return (
    <Flex
      column
      fullWidth
      spacing="s"
      className="px-lg py-md border-b border-gray-200 "
    >
      <Flex fullWidth spacing="s" alignItems="start">
        <ItemImg
          imgDetails={
            typedSimpleItem?.variant?.image ||
            typedSimpleItem?.product?.images[0]
          }
        />
        <Flex column spacing="s">
          <Flex fullWidth spacing="xs">
            {isAutomaticDiscountApplied && (
              <Flex flexWrap="wrap" spacing="s">
                {typedSimpleItem?.discounts?.map((discount, idx) => (
                  <Badge
                    key={idx}
                    type="white"
                    label={
                      <Flex alignItems="center" spacing="xs">
                        <CouponIcon />
                        {getTranslatedDiscountTitle({
                          defaultTitle: discount?.data?.title,
                          currentLocale: activeLocale,
                          translations: discount?.data?.translations,
                        })}
                      </Flex>
                    }
                  />
                ))}
              </Flex>
            )}
          </Flex>
          <Flex column spacing="xs">
            <ProductTitle
              handle={item?.product?.handle || ""}
              title={item?.product?.title || ""}
            />

            {!!typedSimpleItem?.variant?.selectedOptions.length && (
              <span className="text-gray-500 text-xs ">
                {(typedSimpleItem?.variant?.selectedOptions || [])
                  ?.map((option) => option.value.name)
                  .join(" / ")}
              </span>
            )}
            {typedSimpleItem?.variant?.sku && (
              <span className="text-xs italic text-gray-500">
                <FormattedMessage
                  defaultMessage="SKU: {sku}"
                  id="im9OV5"
                  values={{ sku: typedSimpleItem?.variant?.sku }}
                />
              </span>
            )}
            {isLoading ? (
              <FlexRow spacing="s" alignItems="center">
                <P>
                  <FormattedMessage
                    defaultMessage="Calculating..."
                    id="EXlQBN"
                  />
                </P>
                <Spinner inline={true} size={14} />
              </FlexRow>
            ) : (
              <Flex alignItems="center" spacing="xs">
                {isAutomaticDiscountApplied && (
                  <DiscountPrice
                    money={typedSimpleItem?.subtotal}
                    fontSize="14px"
                  />
                )}
                <Price money={typedSimpleItem?.totalPrice} fontSize="14px" />
              </Flex>
            )}
          </Flex>
          <Flex
            justifyContent="start"
            spacing="xs"
            width="100%%"
            alignItems="center"
          >
            <SideCartItemQty
              isDisabled={isItemNotAvailable}
              initialQuantity={typedSimpleItem?.quantity}
              maxQuantityPerCart={typedSimpleItem?.variant?.maxPerCart}
              minQuantityPerCart={typedSimpleItem?.variant?.minPerCart}
              handleQuantityChange={handleQuantityChange}
            />
            <CustomButton
              data-test="button-remove-item"
              onClick={handleRemoveCartItem}
              isDisabled={isLoading}
              compact
              customDefault={{
                backgroundColor: "transparent",
                color: theme.extend.colors.gray[500],
                borderColor: "transparent",
              }}
            >
              <TwoDashTrashIcon />
            </CustomButton>
          </Flex>
        </Flex>
      </Flex>
      {itemErrors?.map((itemError, i) => (
        <Alert
          key={i}
          type={itemError.code === ErrorCodes.PriceChanged ? "warn" : undefined}
          message={itemError.message}
        />
      ))}
    </Flex>
  );
};

const SideCartCustomItem = ({
  item,
  itemErrors,
  isItemNotAvailable,
}: SideCartItemProps) => {
  const {
    cart: { isLoading },

    updateCustomItem: { updateCustomItem },
    removeCustomItem: { removeCustomItem },
  } = useCartData();

  const typedCustomItem = item as CartCustomItem;

  const isCustomDiscountApplied =
    !!typedCustomItem?.discount?.info?.fixed?.amount ||
    !!typedCustomItem?.discount?.info?.percentage;

  const handleRemoveCartItem = async () => {
    await removeCustomItem({
      item: {
        itemId: typedCustomItem?.id || "",
        productId: item?.product?.id || "",
      },
    });
  };

  const handleQuantityChange = async (value: number) => {
    await updateCustomItem({
      item: {
        productId: item?.product?.id || "",
        quantity: value,
      },
    });
  };

  return (
    <Flex
      column
      fullWidth
      spacing="s"
      className="px-lg py-md border-b border-gray-200 "
    >
      <Flex fullWidth spacing="s" alignItems="start">
        <ItemImg imgDetails={typedCustomItem?.product?.images[0]} />
        <Flex column spacing="s">
          <Flex fullWidth spacing="xs">
            {isCustomDiscountApplied && (
              <Badge
                type="white"
                label={
                  <Flex alignItems="center" spacing="xs">
                    <CouponIcon />
                    {typedCustomItem?.discount?.info?.percentage ? (
                      <FormattedMessage
                        defaultMessage="SAVE {percent}%"
                        id="mk79zk"
                        values={{
                          percent: typedCustomItem?.discount?.info?.percentage,
                        }}
                      />
                    ) : (
                      <FormattedMessage
                        defaultMessage="SAVE {fixed}"
                        id="XAESy1"
                        values={{
                          fixed: (
                            <DefaultTextPrice
                              money={typedCustomItem?.discount?.info?.fixed}
                            />
                          ),
                        }}
                      />
                    )}
                  </Flex>
                }
              />
            )}
          </Flex>
          <Flex column spacing="xs">
            <ProductTitle
              handle={`${typedCustomItem?.product?.handle}`}
              itemId={typedCustomItem?.id}
              title={typedCustomItem?.product?.title || ""}
            />
            {isLoading ? (
              <FlexRow spacing="s" alignItems="center">
                <P>
                  <FormattedMessage
                    defaultMessage="Calculating..."
                    id="EXlQBN"
                  />
                </P>
                <Spinner inline={true} size={14} />
              </FlexRow>
            ) : (
              <Flex alignItems="center" spacing="xs">
                {isCustomDiscountApplied && (
                  <DiscountPrice
                    money={typedCustomItem?.subtotal}
                    fontSize="14px"
                  />
                )}
                <Price money={typedCustomItem?.totalPrice} fontSize="14px" />
              </Flex>
            )}
            <Flex
              justifyContent="start"
              spacing="s"
              width="100%%"
              alignItems="center"
            >
              <SideCartItemQty
                isDisabled={isItemNotAvailable}
                initialQuantity={typedCustomItem?.quantity}
                handleQuantityChange={handleQuantityChange}
              />
              <CustomButton
                data-test="button-remove-item"
                onClick={handleRemoveCartItem}
                isDisabled={isLoading}
                compact
                customDefault={{
                  backgroundColor: "transparent",
                  color: theme.extend.colors.gray[500],
                  borderColor: "transparent",
                }}
              >
                <TwoDashTrashIcon />
              </CustomButton>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {itemErrors?.map((itemError, i) => (
        <Alert
          key={i}
          type={itemError.code === ErrorCodes.PriceChanged ? "warn" : undefined}
          message={itemError.message}
        />
      ))}
    </Flex>
  );
};

const ProductTitle = ({
  title,
  handle,
  itemId,
}: {
  title: string;
  handle: string;
  itemId?: string;
}) => {
  const { setSideCartState } = useCartData();
  // const queryParams = itemId ? { itemId } : {};
  return (
    <span
      data-test="text-item-title"
      onClick={() => setSideCartState(false)}
      className="font-semibold cursor-pointer leading-4"
    >
      <Link href={`/product/all/${handle}`}>{title}</Link>
    </span>
  );
};

export default SideCartSingleItem;
