import React from "react";
import { useLocale } from "@/i18n/i18n-client";
import type { Props as SelectProps, StylesConfig } from "react-select";
import { useStore } from "@/lib/storeData/StoreContext";
import { CookieEnum, createCookie } from "@/shared/utils/cookie";
import { ZIndex } from "@/shared/globals/types";
import { LanguageSwitcherContainer } from "./LanguageSwitcherContainer";
import { usePurePathname } from "@/i18n/i18n-navigation";
import { LocalesMap, TLanguage } from "@/i18n/i18n-settings";
import { LazySelect } from "@/shared/globals/LazyElements/react-select";

export const LanguageSwitcher = ({ styles, ...restProps }: SelectProps) => {
  const { locales } = useStore();
  const pathname = usePurePathname();
  const { code: activeLocale } = useLocale();

  if (locales.length === 1) return null;

  const options = locales.map((locale) => ({
    value: locale,
    label: LocalesMap[locale]?.display,
  }));

  const handleChange = (locale: TLanguage) => {
    createCookie(CookieEnum.LANG, locale);
    const href = `/${locale}${pathname}${window.location.search}`;
    document.location.href = href;
  };

  const currentLocaleOption = options.find((option) => {
    if (options.length === 2) return option.value !== activeLocale;
    return option.value === activeLocale;
  });
  return locales.length === 2 ? (
    <div
      onClick={() =>
        handleChange(
          options.find((option) => option.value !== activeLocale)!.value
        )
      }
      style={{ cursor: "pointer" }}
    >
      <LanguageSwitcherContainer>
        {currentLocaleOption?.label}
      </LanguageSwitcherContainer>
    </div>
  ) : (
    <LazySelect
      options={options}
      components={{
        ValueContainer: (props) => (
          <LanguageSwitcherContainer>
            {props.children}
          </LanguageSwitcherContainer>
        ),
      }}
      onChange={(e: { value: TLanguage; label: string }) =>
        handleChange(e.value)
      }
      styles={{
        ...customStyles,
        ...styles,
        control: (base) => ({
          ...base,
          border: 0,
          boxShadow: "none",
          cursor: "pointer",
        }),
        indicatorSeparator: (baseStyles) => ({
          ...baseStyles,
          display: "none",
        }),
        indicatorsContainer: (baseStyles) => ({
          ...baseStyles,
          padding: "0",
        }),
      }}
      defaultValue={currentLocaleOption}
      isSearchable={false}
      isClearable={false}
      hideSelectedOptions
      instanceId="Select-Locale"
      {...restProps}
    />
  );
};

const customStyles: StylesConfig = {
  container: (provided: any) => ({
    ...provided,
    direction: "ltr",
    display: "none",
    "@media (min-width: 768px)": {
      ...provided["@media (min-width: 768px)"],
      display: "block",
    },
  }),
  control: (provided: any) => ({
    ...provided,
    border: "solid 1px #dfe3e8",
    fontWeight: "500",
    cursor: "pointer",
    width: 120,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#252a31",
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: "4px",
    boxShadow: "0 2px 4px 0 rgba(82, 82, 82, 0.2)",
    border: "solid 1px  #dfe3e8",
    backgroundColor: "#fafbfc",
    zIndex: ZIndex["DROPDOWN"],
  }),
  option: (provided: any) => ({
    ...provided,
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "1.43",
    color: "#252a31",
    backgroundColor: "#fafbfc",
    cursor: "pointer",

    ":hover": {
      backgroundColor: "#dfe3e8",
    },
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "#252a31",
  }),
};
