const EmptyCartIcon = () => {
  return (
    <svg width="46" height="58" viewBox="0 0 46 58">
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <path
              fill="#D8D8D8"
              d="M30.992 11.144h11.112c1.009 0 1.976.4 2.69 1.114.713.714 1.114 1.681 1.114 2.69V54.2c0 1.009-.4 1.976-1.114 2.69-.714.713-1.681 1.114-2.69 1.114H30.992v-46.86z"
              transform="translate(-697 -324) translate(697 324)"
            />
            <path
              fill="#969696"
              d="M30.992 53.372v4.596h11.112c2.087 0 3.784-1.681 3.804-3.768V35.372c.003-2.86-1.688-5.451-4.308-6.6l-8.028-3.528-2.58 28.128z"
              transform="translate(-697 -324) translate(697 324)"
            />
            <path
              fill="#E2E2E2"
              d="M3.896 11.144h29.676V55.82c0 .574-.23 1.124-.638 1.527-.408.404-.96.627-1.534.621H3.896c-2.087 0-3.784-1.681-3.804-3.768V14.936c.007-2.096 1.708-3.792 3.804-3.792z"
              transform="translate(-697 -324) translate(697 324)"
            />
            <path
              fill="#D3D3D3"
              d="M33.56 25.244L25.544 17.216 21.764 24.812 12.14 25.844 33.56 47.276z"
              transform="translate(-697 -324) translate(697 324)"
            />
            <path
              fill="#FFF"
              d="M15.644 27.116c-4.57-.695-7.925-4.655-7.86-9.276v-.24c0-.815.66-1.476 1.476-1.476.815 0 1.476.66 1.476 1.476v.276c-.05 3.197 2.312 5.92 5.484 6.324 1.702.172 3.398-.38 4.672-1.522 1.274-1.142 2.01-2.767 2.024-4.478v-.6c.007-.812.664-1.47 1.476-1.476.817 0 1.481.659 1.488 1.476v.6c-.02 2.596-1.151 5.06-3.109 6.764-1.958 1.706-4.553 2.49-7.127 2.152z"
              transform="translate(-697 -324) translate(697 324)"
            />
            <path
              fill="#D8D8D8"
              d="M24.548.032c4.992.013 9.035 4.056 9.048 9.048v2.04h-2.964V9.08c-.145-3.258-2.828-5.826-6.09-5.826s-5.945 2.568-6.09 5.826v2.04H15.5V9.08c.007-4.994 4.054-9.041 9.048-9.048z"
              transform="translate(-697 -324) translate(697 324)"
            />
            <path
              fill="#969696"
              d="M15.5 9.464H18.451999999999998V11.120000000000001H15.5zM30.632 9.464H33.584V11.120000000000001H30.632z"
              transform="translate(-697 -324) translate(697 324)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export { EmptyCartIcon };
