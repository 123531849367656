import { FC } from "react";
import styled from "styled-components";
import { FormattedMessage } from "@/i18n/i18n-client";
import { EmptyState } from "@/components/UtilityComponents/EmptyState";
import { FlexCol, FlexRow, H3, Overlay } from "@/shared/globals";
import { Delete } from "@/assets/Icons/Delete";
import { EmptyCartIllustration } from "@/assets/Icons/EmptyCartIllustration";
import { SideCartProps } from "../../types";
import SideCartSingleItem from "../components/SideCart/SideCartSingleItem";
import SideCartFooter from "../components/SideCart/SideCartFooter";
import { PrimaryButton } from "@/components/Button";
import { rtl, themeColor } from "@/shared/styles-utils";
import { ZIndex } from "@/shared/globals/types";
import { useCartData } from "@/lib/cartData/useCartData";

const SideCart: FC<SideCartProps> = () => {
  const {
    cart: { isLoading, items, cartErrors, isSideCartOpen },
    setSideCartState,
  } = useCartData();

  return (
    <>
      {isSideCartOpen && <Overlay onClick={() => setSideCartState(false)} />}
      <Container isOpen={!!isSideCartOpen}>
        {isSideCartOpen && (
          <>
            <CartHeader fullWidth justifyContent="space-between">
              <H3 fontWeight={700}>
                <FormattedMessage defaultMessage="Shopping Cart" id="KFD6en" />
              </H3>
              <CloseButton
                data-test="button-close-cart"
                reversed
                transparent
                onClick={() => setSideCartState(false)}
              >
                <Delete />
              </CloseButton>
            </CartHeader>
            {items?.length && items ? (
              <>
                <ItemsContainer
                  data-test="cart-items-list"
                  fullWidth
                  spacing="m"
                >
                  {items.map((item) => (
                    <SideCartSingleItem
                      key={item?.id}
                      item={item}
                      errors={cartErrors}
                    />
                  ))}
                </ItemsContainer>
                <SideCartFooter updateCartItemsLoading={!!isLoading} />
              </>
            ) : (
              <EmptyStateWrapper>
                <EmptyState
                  title={
                    <FormattedMessage
                      defaultMessage="Your cart is empty"
                      id="TtTPxt"
                    />
                  }
                  description={
                    <FormattedMessage
                      defaultMessage="Looks like you haven’t added anything to your cart yet"
                      id="jd4m/q"
                    />
                  }
                  image={<EmptyCartIllustration />}
                />
              </EmptyStateWrapper>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default SideCart;

const Container = styled(FlexCol)<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  ${rtl("left", "right")}: 0;
  height: 100%;
  width: 500px;
  background-color: ${({ theme }) => theme.bg.reverse};
  box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.2);
  z-index: ${ZIndex["OVERLAY"]};

  transition: all 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  transform: ${({ isOpen }) =>
    isOpen ? "translateX(0)" : rtl("translateX(-120%)", "translateX(120%)")};

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const ItemsContainer = styled(FlexCol)`
  height: 100%;
  padding: 20px;
  overflow-y: auto;

  /* Set the width and height of the scroll bar track */
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    margin-inline-end: 4px;
  }
  /* Set the color and style of the scroll bar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }
  /* Set the color and style of the scroll bar thumb when hovered */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`;

const CartHeader = styled(FlexRow)`
  padding: 20px;
  box-shadow: 0px 4px 4px 0px rgba(226, 226, 226, 0.5);
  z-index: ${ZIndex["DEFAULT"]};
`;

const CloseButton = styled(PrimaryButton)`
  color: ${themeColor("secondary")};
`;

const EmptyStateWrapper = styled(FlexRow)`
  display: flex;
  height: 100%;
`;
