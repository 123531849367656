const CustomerIcon = ({ color = "#252A31", ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 3C9.80271 3 8 4.80271 8 7C8 9.19729 9.80271 11 12 11C14.1973 11 16 9.19729 16 7C16 4.80271 14.1973 3 12 3ZM12 5C13.1164 5 14 5.88359 14 7C14 8.11641 13.1164 9 12 9C10.8836 9 10 8.11641 10 7C10 5.88359 10.8836 5 12 5ZM12 14C10.255 14 8.18716 14.4098 6.44922 15.0957C5.58025 15.4387 4.7947 15.846 4.16602 16.3691C3.53734 16.8922 3 17.5994 3 18.5V21H21V20V18.5C21 17.5994 20.4627 16.8922 19.834 16.3691C19.2053 15.846 18.4198 15.4387 17.5508 15.0957C15.8128 14.4098 13.745 14 12 14ZM12 16C13.414 16 15.3461 16.374 16.8184 16.9551C17.5545 17.2456 18.177 17.592 18.5547 17.9062C18.9324 18.2205 19 18.4346 19 18.5V19H5V18.5C5 18.4346 5.06759 18.2205 5.44531 17.9062C5.82304 17.592 6.44549 17.2456 7.18164 16.9551C8.65395 16.374 10.586 16 12 16Z"
        fill={color}
      />
    </svg>
  );
};

export { CustomerIcon };
