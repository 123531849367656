import styled from "styled-components";
import { H2 } from "../../../../shared/globals";
import { rtl, themeColor } from "../../../../shared/styles-utils";
import { ZIndex } from "../../../../shared/globals/types";

const DesktopWrapper = styled.nav`
  display: none;
  flex-direction: row;
  width: 100%;
  padding: 5px;
  position: static;

  @media (min-width: 768px) {
    display: block;
    place-content: center;
  }
`;

const CloseMenu = styled.button`
  padding: 10px;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  border: solid 1px ${({ theme }) => theme.special.border};
  background-color: ${themeColor("white")};
  position: absolute;
  top: 6%;
  transform: translateX(${rtl("-50%", "50%")});
  ${rtl("left: 0%", "right: 0%;")};
  &:active {
    @media (min-width: 768px) {
      display: none;
    }
    svg {
      ${rtl("transform: rotate(0deg)", "transform: rotate(180deg)")}
    }
  }
`;

const MobileWrapper = styled.div<{ open: boolean }>`
  background-color: ${themeColor("white")};
  position: fixed;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 300px;
  top: 0;
  ${rtl("right", "left")}: 0;
  transition: all 0.3s ease-in-out;
  opacity: ${({ open }) => (open ? "1" : "0")};
  transform: ${({ open }) =>
    open ? "translateX(0)" : rtl("translateX(120%)", "translateX(-120%)")};
  z-index: ${ZIndex["OVERLAY"]};
  ${CloseMenu} {
    display: ${({ open }) => (open ? "flex" : "none")};
  }
`;

const MobileNavScrollContainer = styled.div`
  overflow: auto;
  display: flex;
  scroll-behavior: smooth;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100% - 45px);
  max-width: 300px;
`;

const FooterFollow = styled.div`
  width: 100%;
`;

const TextFollow = styled(H2)`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  padding: 20px 10px 0;
`;

const MobileNavHeader = styled.div<{
  isFullWidth: boolean;
}>`
  display: flex;
  align-items: center;
  width: 100%;
  color: #252a31;
  font-weight: 600;
  font-size: 16px;
  background: #f5f7f9;
  .active {
    position: relative;
    width: ${({ isFullWidth }) => (isFullWidth ? "100%" : "50%")};
    height: 100%;
    display: flex;
    align-items: center;
    padding: 15px 10px;
    background-color: white;
    transition: all 0.3s ease-in-out;
    &:before {
      content: "";
      background: #252a31;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 4px;
      position: absolute;
    }
  }
`;

const StyledShopButton = styled.div<{
  isFullWidth: boolean;
}>`
  width: ${({ isFullWidth }) => (isFullWidth ? "100%" : "50%")};
  padding: 15px 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

const StyledMyAccountButton = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  padding: 15px 10px;
  cursor: pointer;
  align-items: center;
`;

const StyledLoginButton = styled.div`
  width: 50%;
`;

export {
  DesktopWrapper,
  FooterFollow,
  TextFollow,
  CloseMenu,
  MobileWrapper,
  MobileNavScrollContainer,
  MobileNavHeader,
  StyledShopButton,
  StyledMyAccountButton,
  StyledLoginButton,
};
