import { StoreTemplate } from "@/templates/TemplateLoader";
import { ReactNode } from "react";
import SectionContent from "@/templates/default/elements/SectionContent";

const elements = {
  SectionContent,
};

export function SiteLayoutLoader(props: { children: ReactNode }) {
  StoreTemplate.load("modern", "site-layout", elements);
  return props.children;
}
