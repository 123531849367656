import { StoreMenuItemFragment } from "../../generated/graphql";

type itemType = StoreMenuItemFragment & { children?: StoreMenuItemFragment[] };

export const isCurrentRoute = (
  pathname: string,
  params: any,
  item: itemType
): boolean => {
  return (
    (pathname === "/contact-us" && item.link?.staticPageType === "Contact") ||
    (pathname === "/" && item.link?.staticPageType === "Home") ||
    (pathname === "/shop" && item.link?.staticPageType === "Shop") ||
    (params.handle &&
      item?.link?.resource?.__typename !== "StoreStaticPage" &&
      params.handle === item?.link?.resource?.handle) ||
    (params.collection &&
      item?.link?.resource?.__typename !== "StoreStaticPage" &&
      params.collection === item?.link?.resource?.handle) ||
    false
  );
};
