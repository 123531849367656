import * as React from "react";
import { FormattedMessage } from "@/i18n/i18n-client";
import {
  FooterFollow,
  TextFollow,
  CloseMenu,
  MobileWrapper,
  MobileNavScrollContainer,
  MobileNavHeader,
  HeaderContent,
} from "../components/NavBar/styled";
import { Overlay } from "@/shared/globals";
import AccountButton from "../components/NavBar/MobileAccountButton";
import { MobileLanguageSwitcher } from "../components/NavBar/MobileLanguageSwitcher";
import { SocialLinks } from "@/components/UtilityComponents/SocialLinks";
import { Delete } from "@/assets/Icons/Delete";
import { MyAccountOutlinedIcon } from "@/assets/Icons/MyAccountOutlinedIcon";
import MobileMenu from "../components/NavBar/menu/MobileMenu";
import { useAuth } from "@/lib/Authentication/hooks/useAuth";
import { MobileNavigationProps } from "../../types";
import Logo from "./Logo";
import Flex from "@/shared/globals/UiElements/Flex";
import styled from "styled-components";
import { Link, usePurePathname } from "@/i18n/i18n-navigation";

const MobileNavigation = ({
  open,
  onClose,
  socialSettings,
  menus,
}: MobileNavigationProps) => {
  const auth = useAuth();
  const [isActive, setIsActive] = React.useState(true);
  const pathname = usePurePathname();

  React.useEffect(() => {
    if (!auth.isLoggedIn) {
      setIsActive(true);
    }
  }, [auth.isLoggedIn]);

  return (
    <>
      {open && <Overlay onClick={onClose} />}
      <MobileWrapper open={open}>
        <MobileNavScrollContainer>
          <MobileNavHeader>
            <HeaderContent className="border-b border-gray-200">
              <div className="flex-1">
                <Logo />
              </div>
              <CloseMenu onClick={onClose} className="shadow-md">
                <Delete small />
              </CloseMenu>
            </HeaderContent>
          </MobileNavHeader>
          {auth.isLoggedIn && (
            <TabSwitcher activeTab={isActive} setActiveTab={setIsActive} />
          )}
          {isActive ? (
            <>
              <div className="border-b border-gray-200 w-full">
                <MobileMenu items={menus} />
              </div>
              <FooterFollow>
                {!socialSettings?.length ? null : (
                  <TextFollow>
                    <FormattedMessage
                      defaultMessage="Follow us on"
                      id="gF/sCa"
                    />
                  </TextFollow>
                )}
                <SocialLinks socialSettings={socialSettings} padding={10} />
              </FooterFollow>
            </>
          ) : (
            <AccountButton />
          )}
        </MobileNavScrollContainer>
        <Flex
          className="mobile-navbar-footer p-md border-t border-gray-200 text-gray-700"
          justifyContent="space-between"
          alignItems="center"
          spacing="s"
        >
          {auth.isLoggedIn ? (
            <Link href="/me/my-account">
              <Flex alignItems="center" className="text-sm font-semibold">
                <MyAccountOutlinedIcon />
                {auth.user?.name}
              </Flex>
            </Link>
          ) : (
            pathname !== "/login" &&
            pathname !== "/register" && (
              <Link href="/login">
                <Flex
                  className="rounded-full border border-gray-300 shadow-md h-full py-xs px-sm text-sm font-semibold"
                  alignItems="center"
                  width="fit-content"
                >
                  <MyAccountOutlinedIcon />
                  <FormattedMessage defaultMessage="Login" id="AyGauy" />
                </Flex>
              </Link>
            )
          )}
          <div className="w-max">
            <MobileLanguageSwitcher />
          </div>
        </Flex>
      </MobileWrapper>
    </>
  );
};

export default MobileNavigation;

interface TabSwitcherProps {
  activeTab: boolean;
  setActiveTab: React.Dispatch<React.SetStateAction<boolean>>;
}
const TabSwitcher = ({ activeTab, setActiveTab }: TabSwitcherProps) => {
  return (
    <Flex fullWidth spacing="none">
      <TabStyled
        isActive={activeTab}
        className={`text-base font-semibold text-center flex-1  p-sm border-b "border-gray-200 text-gray-500`}
        onClick={() => setActiveTab(true)}
      >
        <FormattedMessage defaultMessage="Shop" id="Dxd1uB" />
      </TabStyled>
      <TabStyled
        isActive={!activeTab}
        className={`text-base font-semibold text-center flex-1  p-sm border-b "border-gray-200 text-gray-500`}
        onClick={() => setActiveTab(false)}
      >
        <FormattedMessage defaultMessage="My Account" id="2bGejb" />
      </TabStyled>
    </Flex>
  );
};

const TabStyled = styled.div<{
  isActive: boolean;
}>`
  border-color: ${({ isActive, theme }) => isActive && theme.colors.primary};
  border-bottom-width: ${({ isActive }) => (isActive ? "2px" : "1px")};
  color: ${({ isActive, theme }) => isActive && theme.colors.primary};
`;
