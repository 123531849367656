import * as React from "react";
import { FormattedMessage, useIntl } from "@/i18n/i18n-client";
import {
  FooterFollow,
  TextFollow,
  CloseMenu,
  MobileWrapper,
  MobileNavScrollContainer,
  MobileNavHeader,
  StyledShopButton,
  StyledMyAccountButton,
  StyledLoginButton,
} from "../components/NavBar/styled";
import { Overlay } from "@/shared/globals";
import AccountButton from "../components/NavBar/MobileAccountButton";
import { MobileLanguageSwitcher } from "../components/NavBar/MobileLanguageSwitcher";
import { SocialLinks } from "@/components/UtilityComponents/SocialLinks";
import { CustomerIcon } from "@/assets/Icons/CustomerIcon";
import { LeftArrow } from "@/assets/Icons/LeftArrow";
import MobileMenu from "../components/NavBar/menu/MobileMenu";
import { useAuth } from "@/lib/Authentication/hooks/useAuth";
import { MobileNavigationProps } from "../../types";
import { Link, usePurePathname } from "@/i18n/i18n-navigation";

const MobileNavigation = ({
  open,
  onClose,
  socialSettings,
  menus,
}: MobileNavigationProps) => {
  const auth = useAuth();
  const pathname = usePurePathname();
  const [isActive, setIsActive] = React.useState(true);
  const { locale } = useIntl();
  React.useEffect(() => {
    if (!auth.isLoggedIn) {
      setIsActive(true);
    }
  }, [auth.isLoggedIn]);

  return (
    <>
      {open && <Overlay onClick={onClose} />}
      <MobileWrapper open={open}>
        <MobileNavScrollContainer>
          <MobileNavHeader
            isFullWidth={pathname === "/login" || pathname === "/registration"}
          >
            <StyledShopButton
              onClick={() => setIsActive(true)}
              className={isActive ? "active" : ""}
              isFullWidth={
                pathname === "/login" || pathname === "/registration"
              }
            >
              <FormattedMessage defaultMessage="Shop" id="Dxd1uB" />
            </StyledShopButton>
            {auth.isLoggedIn ? (
              <StyledMyAccountButton
                onClick={() => setIsActive(false)}
                className={!isActive ? "active" : ""}
              >
                <CustomerIcon />
                <FormattedMessage defaultMessage="My Account" id="2bGejb" />
              </StyledMyAccountButton>
            ) : pathname === "/login" || pathname === "/registration" ? null : (
              <StyledLoginButton>
                <Link href="/login">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CustomerIcon />
                    <FormattedMessage defaultMessage="Login" id="AyGauy" />
                  </div>
                </Link>
              </StyledLoginButton>
            )}
          </MobileNavHeader>
          {isActive ? (
            <>
              <MobileMenu items={menus} />
              <FooterFollow>
                {!socialSettings?.length ? null : (
                  <TextFollow>
                    <FormattedMessage
                      defaultMessage="Follow us on"
                      id="gF/sCa"
                    />
                  </TextFollow>
                )}
                <SocialLinks socialSettings={socialSettings} padding={10} />
              </FooterFollow>
            </>
          ) : (
            <AccountButton />
          )}
          <CloseMenu onClick={onClose}>
            <LeftArrow rotate={locale === "ar-EG"} />
          </CloseMenu>
        </MobileNavScrollContainer>
        <MobileLanguageSwitcher />
      </MobileWrapper>
    </>
  );
};

export default MobileNavigation;
