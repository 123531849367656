import { useState } from "react";
import styled, { useTheme } from "styled-components";
import { Delete } from "@/assets/Icons/Delete";
import { useStore } from "@/lib/storeData/StoreContext";
import HTMLRender from "@/shared/globals/UiElements/HTMLRender";
import { Section } from "@/shared/layout";
import { ZIndex } from "@/shared/globals/types";
import { InfoBarProps, TemplateElement } from "../../types";
import MotionElementWrapper from "@/shared/globals/MotionElementWrapper";

const InfoBar: TemplateElement<InfoBarProps> = () => {
  const { appearance } = useStore();
  const [isDismissed, setIsDismissed] = useState(false);
  const infoBar = appearance?.infoBar;
  const { isRTL } = useTheme();
  if (!infoBar?.isActive) return null;

  return (
    <StyledBanner backgroundColor={infoBar?.background}>
      <MotionElementWrapper
        initial={{ height: "auto" }}
        animate={{ height: isDismissed ? 0 : "auto" }}
        duration={0.2}
        ease="easeOut"
      >
        <Section>
          <HTMLText html={infoBar?.text} />
        </Section>
        {appearance?.infoBar?.isDismissible && !isDismissed && (
          <div
            onClick={() => setIsDismissed(true)}
            className={`hover:bg-white/20 text-white cursor-pointer absolute top-4 p-1 rounded-full ${
              isRTL ? "left-4" : "right-4"
            } mix-blend-difference !h-auto`}
          >
            <Delete small />
          </div>
        )}
      </MotionElementWrapper>
    </StyledBanner>
  );
};

export default InfoBar;

const StyledBanner = styled.div<{ backgroundColor?: string }>`
  position: sticky;
  top: 0;
  z-index: ${ZIndex["OVERLAY"]};
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ? backgroundColor : theme.brand.wash};
  * {
    height: 100%;
  }

  @media (max-width: 768px) {
    position: unset;
  }
`;

const HTMLText = styled(HTMLRender)`
  max-width: calc(100vw - 65px);
  overflow: hidden;
`;
