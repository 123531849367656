import styled from "styled-components";
import { H2 } from "../../../../shared/globals";
import { rtl, themeColor } from "../../../../shared/styles-utils";
import { ZIndex } from "../../../../shared/globals/types";
import tailwindConfig from "../../../../../tailwind.config";

const DesktopWrapper = styled.nav`
  display: none;
  flex-direction: row;
  width: 100%;
  padding: 5px;
  position: static;

  @media (min-width: 768px) {
    display: block;
    place-content: center;
  }
`;

const CloseMenu = styled.button`
  padding: 10px;
  border-radius: 25px;
  cursor: pointer;
  border: solid 1px ${({ theme }) => theme.special.border};
  background-color: ${themeColor("white")};
  &:active {
    @media (min-width: 768px) {
      display: none;
    }
  }
`;

const MobileWrapper = styled.div<{ open: boolean }>`
  background-color: ${themeColor("white")};
  position: fixed;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 360px;
  top: 0;
  ${rtl("right", "left")}: 0;
  transition: all 0.3s ease-in-out;
  opacity: ${({ open }) => (open ? "1" : "0")};
  transform: ${({ open }) =>
    open ? "translateX(0)" : rtl("translateX(120%)", "translateX(-120%)")};
  z-index: ${ZIndex["OVERLAY"]};
  ${CloseMenu} {
    display: ${({ open }) => (open ? "flex" : "none")};
  }
`;

const MobileNavScrollContainer = styled.div`
  overflow: auto;
  display: flex;
  scroll-behavior: smooth;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100% - 70px);
`;

const FooterFollow = styled.div`
  width: 100%;
`;

const TextFollow = styled(H2)`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  padding: 20px 10px 0;
  color: ${tailwindConfig.theme.extend.colors.gray[500]};
`;

const MobileNavHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  color: #252a31;
  font-weight: 600;
  font-size: 16px;
`;

const StyledShopButton = styled.div`
  width: 50%;
  padding: 15px 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

const StyledMyAccountButton = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  padding: 15px 10px;
  cursor: pointer;
  align-items: center;
`;

const StyledLoginButton = styled.div`
  width: 50%;
`;

const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 16px;
  & > a,
  & > a:active,
  & > a:hover,
  & > div {
    flex: 1;
    text-decoration: none;
    color: #000;
  }
`;

export {
  DesktopWrapper,
  FooterFollow,
  TextFollow,
  CloseMenu,
  MobileWrapper,
  MobileNavScrollContainer,
  MobileNavHeader,
  StyledShopButton,
  StyledMyAccountButton,
  StyledLoginButton,
  HeaderContent,
};
