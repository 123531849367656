import { Global } from "@/assets/Icons/Global";
import Flex from "@/shared/globals/UiElements/Flex";

export const LanguageSwitcherContainer = ({ children }) => {
  return (
    <Flex alignItems="center" spacing="xs">
      <Global />
      <Flex alignItems="center" spacing="none">
        {children}
      </Flex>
    </Flex>
  );
};
