"use client";

import styled from "styled-components";
import type { SiteLayoutProps, TemplateElement } from "../../types";
import { Breakpoints, ZIndex } from "../../../shared/globals/types";
import FooterRenderer from "@/Renderers/FooterRenderer";
import NavBarRenderer from "@/Renderers/NavBarRenderer";
import InfoBar from "../elements/InfoBar";
import NavBar from "../elements/NavBar";
import Footer from "../elements/Footer";
import { SiteLayoutLoader } from "../page-template-loaders/site-layout-loader";
import { useSiteLayout } from "@/shared/utils/useSiteLayout";

const SiteLayout: TemplateElement<SiteLayoutProps> = ({ children }) => {
  const { excludedFooter } = useSiteLayout();

  return (
    <SiteLayoutLoader>
      <Container id="navbar">
        <InfoBar />
        <NavBarRenderer Component={NavBar} />
      </Container>
      <Main applyTempStyles={excludedFooter}>{children}</Main>
      {!excludedFooter && <FooterRenderer Component={Footer} />}
    </SiteLayoutLoader>
  );
};

export default SiteLayout;

/**
 * styles
 */

const Main = styled.main<{ applyTempStyles: boolean }>`
  min-height: 60vh;
  background-color: ${({ theme }) => theme.colors.backgroundReverse};
  padding-bottom: 60px;
  @media (max-width: 768px) {
    padding-bottom: ${({ applyTempStyles }) => applyTempStyles && "120px"};
  }
`;

// used for controlling the overlay inside SearchBar.tsx
const Container = styled.div`
  position: relative;
  z-index: ${ZIndex["OVERLAY"]};
  transition: top 0.3s ease-in-out;
  @media (max-width: ${Breakpoints.TABLET}px) {
    position: sticky;
    top: 0;
  }
`;
