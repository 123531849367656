import React from "react";

export const TwoDashTrashIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9524 17.5031H7.0476C6.06744 17.5031 5.25253 16.7485 5.17735 15.7712L4.37268 5.31055H15.6274L14.8227 15.7712C14.7475 16.7485 13.9326 17.5031 12.9524 17.5031V17.5031Z"
        stroke="#475467"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6695 5.3104H3.33057"
        stroke="#475467"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.6553 2.49707H12.3448C12.8627 2.49707 13.2826 2.91698 13.2826 3.43496V5.31074H6.71741V3.43496C6.71741 2.91698 7.13732 2.49707 7.6553 2.49707Z"
        stroke="#475467"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6413 9.0625V13.752"
        stroke="#475467"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.35873 9.0625V13.752"
        stroke="#475467"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
