import styled from "styled-components";
import { useStore } from "@/lib/storeData/StoreContext";
import { LanguageSwitcher } from "./LanguageSwitcher/LanguageSwitcher";
import type { ValueContainerProps, StylesConfig } from "react-select";
import { ZIndex } from "@/shared/globals/types";
import { Global } from "@/assets/Icons/Global";
import { LazySelectValueContainer } from "@/shared/globals/LazyElements/react-select";

export const MobileLanguageSwitcher = () => {
  const { locales } = useStore();
  if (locales.length === 1) return null;
  return (
    <LanguageSwitcher
      instanceId="mobile-language-switcher"
      menuPlacement="top"
      styles={selectStyles}
      components={{ ValueContainer }}
    />
  );
};

/**
 * Components
 */

const ValueContainer = ({ children, ...props }: ValueContainerProps) => {
  return (
    <LazySelectValueContainer {...props}>
      <FlexRow>
        <Global />
        {children}
      </FlexRow>
    </LazySelectValueContainer>
  );
};

/**
 * Styles
 */

const selectStyles: StylesConfig = {
  container: (provided) => ({
    ...provided,
    width: "100%",
    display: "block",
    position: "sticky",
    bottom: 0,
    zIndex: ZIndex["DEFAULT"],
  }),
  control: (provided) => ({
    ...provided,
    fontWeight: "500",
    cursor: "pointer",
    width: "100%",
    padding: 5,
  }),
};

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin: 0 8px;
    color: ${({ theme }) => theme.bg.wash};
  }
`;
